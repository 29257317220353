<style type="text/css">
  @import "~@/assets/css/common.css";
  @import "~@/assets/css/enroll-form.css";
</style>
<style type="text/css" scoped>
  .modal-title /deep/ {
    text-align: center !important;
    width: 100% !important;
    padding: auto !important;
  }

  /deep/.el-select.blueBorder .el-input__inner {
    border-color: rgb(23, 118, 210);
  }
</style>
<script>
  import Layout from "@/views/layouts/main";
  import CheckHeader from "@/components/check-header";
  import {
    getCheckPowerAreaPage,
    deleteCheckPowerUnitsLocal,
  } from "@/api/admin/exam/checkPower";

  import chooseDefaultPower from "../choose-default-power";
  import addDepartment from "./add-department";
  import addClass from "./add-class";
  import changeMode from "@/views/pages/admin/examination/modeDepartment/change-mode";
  import changeExam from "@/views/pages/admin/examination/change-exam";
  import deleteLocal from "./delete-local.vue";
  import deleteClass from "./delete-class.vue";
  import fastImport from "../fast-import";
  import {
    getDicts
  } from "@/api/common/dict.js";
  import YzSearch from "@/components/form/yzSearch.vue";
  import {
    getNowExam
  } from "@/api/admin/exam/examRecord";
  import {getCheckPowerPage} from "@/api/admin/exam/checkPower";
  import {getStore, setStore} from "../../../../../libs/storage";
  /**
   * Dashboard component
   */
  export default {
    components: {
      Layout,
      CheckHeader,
      addDepartment,
      changeMode,
      changeExam,
      addClass,
      deleteLocal,
      deleteClass,
      chooseDefaultPower,
      fastImport,
      YzSearch,
    },
    data() {
      return {
        title: "考试报名信息审核  / ",
        subTitle: "切换报名",
        items: [{
            text: "用户首页",
            href: "/admin",
          },
          {
            text: "审核单位",
            active: true,
          },
        ],
        pageData: {
          pageNum: 1,
          pageSize: 20,
        },
        dataList: [],
        show1: false,
        show2: false,
        show3: false,
        show4: false,
        show5: false,
        isIndeterminate: false,
        selectList: [],
        ksInfo: {},

        defaultDw: {
          shqxdm: "",
          xkdm: ""
        },
        useDefault: false,

        search: [],
      };
    },
    methods: {
      chooseAll(e) {
        this.selectList = e ?
          this.tableList.map((item) => {
            return item.yhzh;
          }) : [];
        this.isIndeterminate = e;
      },
      //  获取单位列表
      getList() {
        this.pageData.ksbmbh = this.ksInfo.ksbmbh;
        this.pageData.shfl = 2;
        getCheckPowerAreaPage(this.pageData).then((res) => {
          if (res.status) {
            this.dataList = res.data;
            this.pageData.total = res.total;
            this.pageData.pageNum = res.pageNum;
            this.pageData.pageSize = res.pageSize;
          }
        });
      },
      exportExcel(){
        let params = JSON.parse(JSON.stringify(this.pageData))
        params.isExport = true
        getCheckPowerPage(params)
      },
      // 删除
      deleteItem(dwm) {
        this.$confirm(`是否确认删除?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          deleteCheckPowerUnitsLocal(this.ksInfo.ksbmbh, dwm).then((res) => {
            if (res.status) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.getList();
            }
          });
        });
      },
      handleSizeChange(val) {
        this.pageData.pageSize = val;
        this.getList();
      },
      handleCurrentChange(val) {
        this.pageData.pageNum = val;
        setStore("dpNum",val)
        this.getList();
      },
      editItem(item, show) {
        this.useDefault = true;
        this.defaultDw.jgdm = item.jgdm;
        this.defaultDw.jgmc = item.jgmc;
        this.defaultDw.dwdm = item.dwdm;
        this.defaultDw.dwmc = item.dwmc;
        this.defaultDw.sid1 = item.sid1;
        this.defaultDw.sid2 = item.sid2;
        this.defaultDw.shqxdm = item.shqxdm;
        this.defaultDw.xkdm = item.xkdm;
        this.defaultDw.shqxmc = item.shqxmc;
        this.defaultDw.xkmc = item.xkmc;
        this.defaultDw.ksbmbh = this.ksInfo.ksbmbh;
        this.defaultDw.ksmc = this.ksInfo.ksmc;
        show.show()
      },
      addItem(show) {
        this.useDefault = false;
        show.show()
      },
    },

    mounted() {
      let num=getStore('dpNum')
      let flag=this.$route.query.flag ? this.$route.query.flag : "";
      if(flag){
        this.pageData.pageNum=Number(num)
      }
      getNowExam().then(res => {
        if (res.status) {
          this.ksInfo = res.data
          this.getList();
        }
      })
      getDicts("ssjg").then((res) => {
        this.dict = res;
        this.search = [{
            title: "机构",
            bound: ["jgdm"],
            type: "select",
            dict: JSON.parse(JSON.stringify(res.ssjg)),
          },
          {
            title: "单位编号/单位名称",
            bound: "keyword",
            type: "input",
          },
        ];
      });
    },
  };
</script>

<template>
  <Layout>
    <CheckHeader :title="title" :title2="ksInfo.ksmc" :subTitle="subTitle" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 600px">
          <div class="card-body">
            <div class="pb-3" style="display: flex; align-items: center">
              <div style="margin-right: 10px">
                审核方式：本考试采用审核模式为
                <span style="color: red">按区域+专业方式</span>
                审核
              </div>
              <b-button style="height: 20px" variant="outline-info" class="flexList mr-2 condition"
                @click="$refs.changeMode.show()">更换审核方式</b-button>
              <b-button
                  style="height: 20px"
                  variant="outline-info"
                  class="flexList mr-2 condition"
                  @click="$refs.chooseDefaultPower.show()"
              >设置默认权限</b-button
              >
            </div>
            <div class="check-table-top">
              <div class="flexList" style="flex: 1">
                <yz-search :data="search" v-model="pageData" @seachList="getList"></yz-search>
                <b-button variant="outline-info" class="flexList mr-2 condition" @click="addItem($refs.addDepartment)">
                  <i class="iconfont icon-plus-circle1 mr-2 font-size-20"></i>添加审核单位
                </b-button>
                <b-button
                    variant="outline-info"
                    class="flexList mr-2 condition"
                    @click="$refs.fastImport.show()"
                ><i class="iconfont icon-plus-circle1 mr-2 font-size-20"></i
                >导入审核名单</b-button
                >
              </div>
              <div class="d-flex">
                <div class="border-blue export-tab"
                     @click="exportExcel"><i
                    class="iconfont icon-antOutline-file-excel mr-2"></i>excel</div>
              </div>
            </div>
            <div class="table-responsive border mt-3">
              <table class="table light-table table-hover">
                <thead class="thead-light">
                  <tr>
                    <th style="width: 4%">序号</th>
                    <th style="width: 5%">审核编号</th>
                    <th style="width: 10%">审核机构名称</th>
                    <th style="width: 10%">审核单位名称</th>
                    <th style="width: 10%">账号（用/总）</th>
                    <th>被审区域</th>
                    <th style="width: 25%">被审专业</th>
                    <th style="width: 8%">更新时间</th>
                    <th style="width: 8%">更新人</th>
                    <th style="width: 4%" class="text-center">编辑</th>
                    <th style="width: 4%" class="text-center">删除</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, i) in dataList" :key="i">
                    <td scope="row">{{ (pageData.pageNum - 1) * pageData.pageSize +  i + 1 }}</td>
                    <td>{{ item.dwdm }}</td>
                    <td>{{ item.jgmc }}</td>
                    <td>
                      <router-link :to="{
                            path: '/admin/examinationAuthority',
                            query: {
                              dwdm: item.dwdm,
                              dwmc: item.dwmc,
                              shfl: 2,
                              ksbmbh: ksInfo.ksbmbh,
                              ksmc: ksInfo.ksmc,
                            },
                          }" class="font-blue">{{ item.dwmc }}</router-link>
                    </td>
                    <td>{{ item.syrs }}/{{ item.zrs }}</td>
                    <td>
                      <span class="line2" :title="item.shqxmc">
                        <a href="javascript:;" class="font-blue">
                          {{item.shqxmc}}
                        </a>
                      </span>
                    </td>
                    <td>
                      <span class="line2" :title="item.xkmc">
                        <a href="javascript:;" class="font-blue">
                          {{item.xkmc}}
                        </a>
                      </span>
                    </td>
                    <td>{{ formatDate(item.modifyTime) }}</td>
                    <td>{{ item.modifyUser }}</td>
                    <td class="text-center">
                      <a href="javascript:;"  @click="editItem(item, $refs.addDepartment)">
                        <i class="iconfont icon-edit-two text-dark align-middle mr-1"></i>
                      </a>
                    </td>
                    <td class="tab-icon text-center">
                      <i @click="deleteItem(item.dwdm)"
                        class="iconfont icon-riLine-delete-bin-line align-middle mr-1"></i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="float-right d-flex">
              <el-pagination @size-change="handleSizeChange" background @current-change="handleCurrentChange"
                :current-page.sync="pageData.pageNum" :page-size="pageData.pageSize"
                layout="total, sizes,jumper, prev, pager, next" :total="pageData.total">
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <changeExam></changeExam>
    <changeMode ref="changeMode"></changeMode>
    <fastImport ref="fastImport" @success="getList"></fastImport>
    <deleteLocal ref="deleteLocal" :defaultDw="defaultDw" :useDefault="useDefault" :ksInfo="ksInfo" @success="getList">
    </deleteLocal>
    <deleteClass ref="deleteClass" :defaultDw="defaultDw" :useDefault="useDefault" :ksInfo="ksInfo" @success="getList">
    </deleteClass>
    <addDepartment ref="addDepartment" :defaultDw="defaultDw" :useDefault="useDefault" @success="getList"
      :ksInfo="ksInfo"></addDepartment>
    <addClass ref="addClass" :defaultDw="defaultDw" :useDefault="useDefault" :ksInfo="ksInfo" @success="getList">
    </addClass>
    <chooseDefaultPower ref="chooseDefaultPower"></chooseDefaultPower>
  </Layout>
</template>
