<template>
  <b-modal
    v-model="showUser"
    centered
    title="删除审核专业"
    size="lg"
    title-class="font-18"
    hide-footer
  >
    <div class="d-flex check-distri mb-3">
      <label
        >{{ ksInfo.ksmc }}{{ useDefault ? "/" + defaultDw.dwmc : "" }}</label
      >
    </div>
    <div class="table-responsive border mt-3">
      <el-tag
        style="margin: 2px"
        v-for="(tag, i) in deleteList"
        :key="i"
        closable
        @close="closeTag(i, deleteSidList[i])"
        type="primary"
      >
        {{ tag }}
      </el-tag>
    </div>
    <div class="mt-3 text-center">
      <button
        type="button"
        class="btn btn-info h30 w-md mr-3"
        @click="deleteStart"
      >
        确定提交
      </button>
      <button
        type="button"
        class="btn btn-secondary h30 w-md"
        @click="showUser = false"
      >
        关闭窗口
      </button>
    </div>
  </b-modal>
</template>

<script>
import {deleteCheckPowerUnitsClass} from "@/api/admin/exam/checkPower";

export default {
  props: {
    ksInfo: {
      default: {},
    },
    useDefault: {
      default: false,
    },
    defaultDw: {
      default: {},
    },
  },
  data() {
    return {
      deleteList: [],
      deleteSidList: [],
      deleteTaskList: [],
      showUser: false,
    };
  },
  methods: {
    show(){
      this.showUser = true
    },
    hide(){
      this.showUser = false
    },
    closeTag(i, e) {
      this.deleteList.splice(i, 1);
      this.deleteSidList.splice(i, 1);
      this.deleteTaskList.push(e);
    },
    deleteStart() {
      this.$confirm(`是否确认提交?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteCheckPowerUnitsClass(this.deleteTaskList.join(",")).then(
          (res) => {
            if (res.status) {
              this.$message({
                type: "success",
                message: "提交成功!",
              });
              this.hide()
              this.$emit("success");
            }
          }
        );
      });
    },
  },
  components: {},
  mounted() {},
  watch: {
    "defaultDw.xkmc": {
      deep: true,
      handler(e) {
        if (e){
          this.deleteList = e.split(",");
        }
      },
    },
    "defaultDw.sid2": {
      deep: true,
      handler(e) {
        if (e){
          this.deleteSidList = e.split(",");
        }
      },
    },
  },
};
</script>

<style></style>
