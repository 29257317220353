<template>
  <b-modal
    v-model="showUser"
    centered
    title="增加学科"
    size="lg"
    title-class="font-18"
    hide-footer
  >
    <div class="d-flex check-distri mb-3">
      <label>{{ ksInfo.ksmc }}{{useDefault?'/':''}}{{defaultDw.dwmc}}</label>
    </div>
    <div class="d-flex check-distri mb-3" v-if="!useDefault">
      <label style="width: 10%">选择单位</label>
      <yz-select
        style="width: 30%; margin-right: 10px"
        :dict="dict.ssjg"
        :bound="['jgdm', 'jgmc']"
        v-model="pageData"
      >
      </yz-select>
      <yz-select
        style="width: 30%"
        :dict="dict.bm"
        :bound="['dwdm', 'dwmc']"
        filter="jgdm"
        v-model="pageData"
      >
      </yz-select>
    </div>
    <div class="table-responsive border mt-3">
      <el-checkbox-group
        v-model="chooseOrgPerson"
        style="max-height: 300px; overflow-y: auto"
      >
        <table class="table light-table table-hover">
          <thead class="thead-light">
            <tr>
              <th style="width: 5%">
                <el-checkbox
                  :indeterminate="isIndeterminate"
                  class="mr-1"
                  @change="selectAll"
                >
                </el-checkbox
                >全选
              </th>
              <th style="width: 10%">科目代码</th>
              <th style="width: 10%">科目级别</th>
              <th style="width: 10%">科目名称</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(obj, index) in kmList" :key="index">
              <td>
                <el-checkbox :label="obj" class="mr-1">{{
                  index + 1
                }}</el-checkbox>
              </td>
              <td>{{ obj.xkdm }}</td>
              <td>{{ obj.jbmc }}</td>
              <td>
                {{ obj.xkmc }}
              </td>
            </tr>
          </tbody>
        </table>
      </el-checkbox-group>
    </div>
    <div class="mt-3 text-center">
      <button type="button" class="btn btn-info h30 w-md mr-3" @click="addDep">
        确定添加
      </button>
      <button
        type="button"
        class="btn btn-secondary h30 w-md"
        @click="showUser = false"
      >
        关闭窗口
      </button>
    </div>
  </b-modal>
</template>

<script>
import {getDicts} from "@/api/common/dict.js";
import YzSelect from "@/components/form/yzSelect.vue";
import {addCheckPowerClassBatch} from "@/api/admin/exam/checkPower";
import {getSubjectLimit} from "@/api/admin/exam/subjectLimit";

export default {
  props: {
    defaultDw: {
      default: {}
    },
    useDefault: {
      default: false,
    },
    ksInfo: {
      default: {}
    },
  },
  data() {
    return {
      checkAll: false,
      chooseChecks: [],
      chooseOrgPerson: [],
      isIndeterminate: false,
      search: [],
      pageData: {
        jgdm: "",
      },
      pageData1: {
        lbbm: "",
        flbm: "",
      },
      dict: {},
      kmList: [],
      temp: {},
      showUser: false
    };
  },
  methods: {
    show(){
      this.chooseOrgPerson = []
      getSubjectLimit({ksbmbh: this.ksInfo.ksbmbh}).then(res=>{
        if (res.status){
          this.kmList = res.data.list
        }
      })
      this.showUser = true
    },
    hide(){
      this.showUser = false
    },
    outFilter(e){
      this.kmList = e
    },
    selectAll(e) {
      this.chooseOrgPerson = e ? this.kmList : [];
      this.isIndeterminate = e;
    },
    addDep() {
      let params = [];
      this.chooseOrgPerson.forEach((item) => {
        if (!this.useDefault){
          params.push({
            ksbmbh: this.ksInfo.ksbmbh,
            ksmc: this.ksInfo.ksmc,
            dwdm: this.pageData.dwdm,
            dwmc: this.pageData.dwmc,
            jgdm: this.pageData.jgdm,
            jgmc: this.pageData.jgmc,
            xkmc: item.xkmc,
            xkdm: item.xkdm,
          });
        } else {
          params.push({
            ksbmbh: this.defaultDw.ksbmbh,
            ksmc: this.defaultDw.ksmc,
            dwdm: this.defaultDw.dwdm,
            dwmc: this.defaultDw.dwmc,
            jgdm: this.defaultDw.jgdm,
            jgmc: this.defaultDw.jgmc,
            xkmc: item.xkmc,
            xkdm: item.xkdm,
          });
        }
      });
      addCheckPowerClassBatch(params).then((res) => {
        if (res.status) {
          this.$message({
            type: "success",
            message: "新增成功!",
          });
          this.hide()
          this.$emit("success");
        }
      });
    },
  },
  components: {
    YzSelect,
  },
  mounted() {
    getDicts("ksdl", "ejlb", "xk", "ssjg", "bm").then((res) => {
      this.dict = res;
    });
  }
};
</script>

<style></style>
