<template>
  <b-modal
    v-model="showUser"
    v-if="showUser"
    centered
    :title="(useDefault ? '编辑' : '新增') + '审核区域'"
    size="lg"
    title-class="font-18"
    hide-footer
  >
    <div class="d-flex check-distri mb-3">
      <label
        >{{ ksInfo.ksmc }}{{ useDefault ? "/" + defaultDw.dwmc : "" }}</label
      >
    </div>
    <div class="d-flex check-distri mb-3" v-if="!useDefault">
      <label style="width: 10%">选择单位</label>
      <yz-select
        style="width: 30%; margin-right: 10px"
        :dict="dict.ssjg"
        :bound="['jgdm', 'jgmc']"
        v-model="pageData"
      >
      </yz-select>
      <yz-select
        style="width: 30%"
        :dict="dict.bm"
        :bound="['dwdm', 'dwmc']"
        filter="jgdm"
        v-model="pageData"
      >
      </yz-select>
    </div>
    被审区域：
    <div class="table-responsive border mt-3">
      <el-checkbox :indeterminate="checkAllLocal" @change="checkLocalChange">全选</el-checkbox>
      <el-checkbox-group
        v-model="chooseOrgPerson"
        style="max-height: 300px; overflow-y: auto"
      >
        <el-checkbox  :checked="obj.checked" v-for="(obj, index) in qyList" :key="index" :label="obj" class="mr-1">{{obj.name}}</el-checkbox>
      </el-checkbox-group>
    </div>
    被审专业：
    <div class="table-responsive border mt-3">
      <el-checkbox :indeterminate="checkAllClass" @change="checkClassChange">全选</el-checkbox>
      <el-checkbox-group
          v-model="chooseOrgPerson1"
          style="max-height: 300px; overflow-y: auto"
      >
        <el-checkbox  :checked="obj.checked" v-for="(obj, index) in kmList" :key="index" :label="obj" class="mr-1">[{{ obj.jbmc }}]{{ obj.xkmc }}</el-checkbox>
      </el-checkbox-group>
    </div>
    <div class="mt-3 text-center">
      <button type="button" class="btn btn-info h30 w-md mr-3" @click="addDep">
        确定添加
      </button>
      <button
        type="button"
        class="btn btn-secondary h30 w-md"
        @click="showUser = false"
      >
        关闭窗口
      </button>
    </div>
  </b-modal>
</template>

<script>
import {getDicts} from "@/api/common/dict.js";
import {orgUnitsList} from "@/api/admin/base/org";
import YzSelect from "@/components/form/yzSelect.vue";
import {addCheckPowerAreaBatch} from "@/api/admin/exam/checkPower";
import {getAreaList} from "@/api/admin/exam/examRecord";
import {getSubjectLimit} from "@/api/admin/exam/subjectLimit";
import {addCheckPowerClassBatch} from "@/api/admin/exam/checkPower";

export default {
  props: {
    ksInfo: {
      default: {},
    },
    defaultDw: {
      default: {},
    },
    useDefault: {
      default: false,
    },
  },
  data() {
    return {
      checkAll: false,
      chooseOrgPerson: [],
      chooseOrgPerson1: [],
      isIndeterminate: false,
      search: [],
      pageData: {
        jgdm: "",
      },
      dict: {},
      tableList: [],
      qyList: [],
      kmList: [],
      showUser: false,
      count: 0,
      default:{
        shqxdm:"",
        xkdm:"",
      },
      checkAllLocal: false,
      checkAllClass: false,
    };
  },
  methods: {
    checkLocalChange(e){
      this.checkAllLocal = e
      this.chooseOrgPerson = e?this.qyList:[]
    },
    checkClassChange(e){
      this.checkAllClass = e
      this.chooseOrgPerson1 = e?this.kmList:[]
    },
    show(){
      this.default.xkdm = this.defaultDw.xkdm
      this.default.shqxdm = this.defaultDw.shqxdm
      this.chooseOrgPerson = []
      this.chooseOrgPerson1 = []
      this.count = 0
      getSubjectLimit({ksbmbh: this.ksInfo.ksbmbh}).then(res=>{
        if (res.status){
          this.kmList = res.data.list
          this.kmList.forEach(item=>{
            item.checked = this.isCheck(this.default.xkdm, item.xkdm)
          })
          getAreaList({ksbmbh: this.ksInfo.ksbmbh}).then(res=>{
            if (res.status){
              this.qyList = res.data.map(item=>{
                if (item.qxmc){
                  item['value'] = item.qxdm
                  item['name'] = item.qxmc
                } else {
                  item['value']  = item.ssdm + item.dszdm
                  item['name'] = item.dszmc
                }
                return item;
              })
              this.qyList.forEach(item=>{
                item.checked = this.isCheck(this.default.shqxdm, item.value)
              })
              this.$forceUpdate()
              this.showUser = true
            }
          })
        }
      })

    },
    hide(){
      this.showUser = false
    },
    getList() {
      orgUnitsList("", this.pageData).then((res) => {
        if (res.status) {
          this.tableList = res.data;
          this.pageData.total = res.data.total;
        }
      });
    },
    addDep() {
      let params = [];
      this.chooseOrgPerson.forEach((item) => {
        if (!this.useDefault) {
          params.push({
            ksbmbh: this.ksInfo.ksbmbh,
            ksmc: this.ksInfo.ksmc,
            dwdm: this.pageData.dwdm,
            dwmc: this.pageData.dwmc,
            jgdm: this.pageData.jgdm,
            jgmc: this.pageData.jgmc,
            shssdm: this.pageData.shssdm,
            shssmc: this.pageData.shssmc,
            shdjsdm: this.pageData.shdjsdm,
            shdjsmc: this.pageData.shdjsmc,
            shqxmc: item.name,
            shqxdm: item.value,
          });
        } else {
          params.push({
            ksbmbh: this.defaultDw.ksbmbh,
            ksmc: this.defaultDw.ksmc,
            dwdm: this.defaultDw.dwdm,
            dwmc: this.defaultDw.dwmc,
            jgdm: this.defaultDw.jgdm,
            jgmc: this.defaultDw.jgmc,
            shssdm: this.pageData.shssdm,
            shssmc: this.pageData.shssmc,
            shdjsdm: this.pageData.shdjsdm,
            shdjsmc: this.pageData.shdjsmc,
            shqxmc: item.name,
            shqxdm: item.value,
          });
        }
      });
      addCheckPowerAreaBatch(this.ksInfo.ksbmbh, this.defaultDw.dwdm, params).then((res) => {
        if (res.status) {
          this.addClass()
          this.success()
        }
      });
    },
    addClass() {
      let params = [];
      this.chooseOrgPerson1.forEach((item) => {
        if (!this.useDefault){
          params.push({
            ksbmbh: this.ksInfo.ksbmbh,
            ksmc: this.ksInfo.ksmc,
            dwdm: this.pageData.dwdm,
            dwmc: this.pageData.dwmc,
            jgdm: this.pageData.jgdm,
            jgmc: this.pageData.jgmc,
            xkmc: item.xkmc,
            xkdm: item.xkdm,
          });
        } else {
          params.push({
            ksbmbh: this.defaultDw.ksbmbh,
            ksmc: this.defaultDw.ksmc,
            dwdm: this.defaultDw.dwdm,
            dwmc: this.defaultDw.dwmc,
            jgdm: this.defaultDw.jgdm,
            jgmc: this.defaultDw.jgmc,
            xkmc: item.xkmc,
            xkdm: item.xkdm,
          });
        }
      });
      addCheckPowerClassBatch(this.ksInfo.ksbmbh, this.defaultDw.dwdm, params).then((res) => {
        if (res.status) {
          this.success()
        }
      });
    },
    success(){
      this.count++;
      if (this.count == 2){
        this.$message({
          type: "success",
          message: "新增成功!",
        });
        this.hide()
        this.$emit("success");
      }
    },
    isCheck(a, b){
      if (!this.useDefault){
        return false;
      }
      if (typeof a == 'string' && typeof b == 'string'){
        return (","+a+",").lastIndexOf(","+b+",") >= 0
      }
      return false
    }
  },
  components: {
    YzSelect,
  },
  mounted() {
    this.getList();
    getDicts("dict_xzqh", "ssjg", "bm").then((res) => {
      this.dict = res;
      this.search = [
        {
          title: "机构",
          bound: ["jgdm"],
          type: "select",
          dict: JSON.parse(JSON.stringify(res.ssjg)),
        },
        {
          title: "单位",
          bound: ["dwdm"],
          type: "select",
          filter: "jgdm",
          dict: JSON.parse(JSON.stringify(res.bm)),
        },
      ];
    });
  }
};
</script>

<style></style>
