<template>
  <b-modal
      v-model="examDiv"
      title="快速导入考试报名库"
      size="lg"
      centered
      title-class="font-18"
      hide-footer
  >
    <div class="flexList">
      <el-select
          class="mr-2"
          style="width: 20%"
          placeholder="请选择考试年份"
          v-model="examParam.ksnf"
          size="small"
      >
        <el-option
            v-for="(item, i) in years"
            :label="item.label"
            :value="item.value"
            :key="i"
        >
        </el-option>
      </el-select>
      <input
          placeholder="关键字"
          class="h30 form-control border-blue mr-2"
          v-model="examParam.ksmc"
          maxlength="50"
          style="width: 156px"
      />
      <button
          type="button"
          class="btn btn-info h30 flexList mr-2"
          @click="getExamList"
      >
        <i class="iconfont mr-2 icon-mb-search"></i>查询
      </button>
    </div>
    <div class="table-responsive border mt-3">
      <table class="table light-table table-hover">
        <thead class="thead-light">
        <tr>
          <th>序号</th>
          <th>年份</th>
          <th>考试名称</th>
          <th>选择</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, i) in examList" :key="item.sid">
          <td>{{ i + 1 }}</td>
          <td>{{ item.ksnf }}</td>
          <td>
            <a
                href="javascript:void(0)"
                @click="changeExam(item)"
                class="blue-font"
            >{{ item.ksmc }}</a
            >
          </td>
          <td>
            <b-button size="sm" variant="info" @click="importExam(item)"
            >导入</b-button
            >
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </b-modal>
</template>

<script>
import {getExamEasyNow, importExam
    } from "@/api/admin/exam/examRecord.js";
export default {
  name: "change-exam",
  data(){
    return {
      examDiv: false,
      examParam: {
        ksnf: new Date().getFullYear(),
        ksmc: "",
      },
      examList: [],
      years:[]
    }
  },
  methods:{
    show(){
      this.examDiv = true
    },
    getYear() {
      //获取年份，当前年前后几年
      var y = new Date().getFullYear();
      for (var i = 0; i <= 5; i++) {
        if (i < 5) {
          this.years.unshift({
            value: y - i,
            label: y - i,
          });
        } else {
          for (var x = 1; x < 3; x++) {
            this.years.push({
              value: y + x,
              label: y + x,
            });
          }
        }
      }
    },
    // 获取考试列表
    getExamList() {
      getExamEasyNow(this.examParam).then((res) => {
        if (res.status) {
          this.examList = res.data;
        }
      });
    },
    // 导入审核人员
    importExam(item){
      importExam(item).then((res) => {
        if (res.status) {
          this.$notify.success("导入成功")
          this.examDiv = false
          this.$emit("success")
        }
      });
    }
  },
  mounted() {
    this.getYear()
    this.getExamList()
  }
}
</script>

<style scoped>

</style>